(function ($, window, document) {
	let winWidth = window.innerWidth;

	// Fixing querySelectorAll nodelist for IE11 support
	// Makes browser know to treat nodelist like an array
	if (typeof NodeList.prototype.forEach !== 'function') {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}

	// Date
	let d = new Date();
	let y = d.getFullYear();
	document.querySelector('.currentYear').innerHTML = y;

	if (document.querySelector('body').classList.contains('home')) {
		// Alert handling
		if (window.location.hash) {
			let hash = window.location.hash.substring(1),
				thanksAlert = document.querySelector('.alert');
			if (hash == 'thanks') {
				thanksAlert.classList.add('show');
			}
		}

		// Iframe Resize
		function resizeIframe(iFrame) {
			// iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
			iFrame.width = 100 + '%';
			iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
			iFrame.style.height = iFrame.height + 'px';
		}
		let form = document.querySelector('.embed-responsive-item'),
			formWindow = form.contentDocument || form.contentWindow.document,
			formFrame = form.contentWindow;

		$(function () {
			var onIframeReady = function ($i, successFn, errorFn) {
				try {
					const iCon = $i.first()[0].contentWindow,
						bl = 'about:blank',
						compl = 'complete';
					const callCallback = () => {
						try {
							const $con = $i.contents();
							if ($con.length === 0) {
								// https://git.io/vV8yU
								throw new Error('iframe inaccessible');
							}
							successFn($con);
						} catch (e) {
							// accessing contents failed
							errorFn();
						}
					};
					const observeOnload = () => {
						$i.on('load.jqueryMark', () => {
							try {
								const src = $i.attr('src').trim(),
									href = iCon.location.href;
								if (href !== bl || src === bl || src === '') {
									$i.off('load.jqueryMark');
									callCallback();
								}
							} catch (e) {
								errorFn();
							}
						});
					};
					if (iCon.document.readyState === compl) {
						const src = $i.attr('src').trim(),
							href = iCon.location.href;
						if (href === bl && src !== bl && src !== '') {
							observeOnload();
						} else {
							callCallback();
						}
					} else {
						observeOnload();
					}
				} catch (e) {
					// accessing contentWindow failed
					errorFn();
				}
			};

			var $iframe = $(form);
			onIframeReady(
				$iframe,
				function ($contents) {
					console.log('Ready to go');
					resizeIframe(form);
					// console.log($contents.find("*"));
				},
				function () {
					console.log('Can not access iframe');
				}
			);
		});

		formFrame.addEventListener('click', function (e) {
			setTimeout(function () {
				resizeIframe(form);
			}, 100);
		});
		formFrame.addEventListener('blur', function (e) {
			setTimeout(function () {
				resizeIframe(form);
			}, 100);
		});

		// Section Toggle
		const sectionTogs = document.querySelectorAll('.section-toggle'),
			navTogs = document.querySelectorAll('.nav-toggle'),
			elements = document.querySelectorAll('.hidden-row');

		sectionTogs.forEach((tog) => {
			const target = tog.dataset.target;

			tog.addEventListener('click', function (el) {
				el.preventDefault();

				const element = document.querySelector(
					'[data-element=' + target + ']'
				);

				element.classList.toggle('visible');
			});
		});
		navTogs.forEach((tog) => {
			const target = tog.dataset.target;

			tog.addEventListener('click', function (e) {
				e.preventDefault();

				const element = document.querySelector(
					'[data-element=' + target + ']'
				);

				if (!element.classList.contains('visible')) {
					element.classList.add('visible');
				}
			});
		});

		// Hidden Section Collapse Buttons
		const collapseBtns = document.querySelectorAll('.section-collapse');

		collapseBtns.forEach((btn) => {
			// const parSection = btn.parentElement; Just finds immediate parent
			const parSection = btn.closest('.content-row'); // Continues up tree until selector is found

			btn.addEventListener('click', function (el) {
				el.preventDefault();

				parSection.classList.remove('visible');
			});
		});

		// FAQ Toggles
		if (document.querySelector('div').classList.contains('.faq-wrap')) {
			const faqs = document.querySelectorAll('.faq-wrap');

			faqs[0].classList.add('toggled');

			faqs.forEach((faq) => {
				const title = faq.querySelector('.faq-title'),
					toggle = title.querySelector('.toggle-icon');
				title.addEventListener('click', function (el) {
					faq.classList.toggle('toggled');
				});
				toggle.addEventListener('click', function (el) {
					el.preventDefault();
				});
			});
		}

		function insertAfter(referenceNode, newNode) {
			referenceNode.parentNode.insertBefore(
				newNode,
				referenceNode.nextSibling
			);
		}

		function readMore(items) {
			items.forEach(function (item) {
				var firstP = item.firstElementChild.clientHeight,
					counter = 0,
					children = item.querySelectorAll('p');

				children.forEach(function (child) {
					var height = child.clientHeight;
					if (counter < 1) {
						firstP = height;
					}
					counter++;
				});

				item.style.maxHeight = '76px';
				//item.style.maxHeight = firstP + 'px';

				if (children.length > 1) {
					var loadMore = document.createElement('button'),
						loadMoreInner = document.createElement('span'),
						loadMoreIcon = document.createElement('i');

					loadMoreInner.innerHTML = 'Read more';
					loadMore.classList.add('read-more');

					loadMoreIcon.classList.add('far', 'fa-arrow-down');
					loadMore.appendChild(loadMoreInner);
					loadMore.appendChild(loadMoreIcon);

					insertAfter(item, loadMore);

					loadMore.addEventListener('click', function (e) {
						if (loadMore.classList.contains('dropped')) {
							var counter = 0,
								height = item.clientHeight;
							if (counter > 1) {
								firstP = height;
							}
							counter++;

							//item.style.maxHeight = firstP + 'px';
							item.style.maxHeight = '76px';

							loadMore.classList.remove('dropped');
							loadMoreInner.innerHTML = 'Read more';
						} else {
							item.style.maxHeight = '3000' + 'px';
							loadMore.classList.add('dropped');
							loadMoreInner.innerHTML = 'Read less';
						}
					});
				}
			});
		}

		var readMores = document.querySelectorAll('div.read-more');

		if (readMores.length) {
			readMore(readMores);
		}

		// Team Member Bio Toggles
		const members = document.querySelectorAll('.team-member');

		members.forEach((member) => {
			let bio = member.querySelector('.member-bio'),
				bioTog = member.querySelector('.member-bio-toggle');

			bio.classList.add('ellip-init');

			let ellipsis = new Ellipsis(bio);

			ellipsis.calc();
			ellipsis.set();

			if (bioTog) {
				bioTog.addEventListener('click', function (el) {
					el.preventDefault();
					let label = bioTog.querySelector('span'),
						icon = bioTog.querySelector('.svg-inline--fa');

					// console.log(label.innerText);

					bio.classList.toggle('ellip-init');

					if (bio.classList.contains('ellip-init')) {
						ellipsis.calc();
						ellipsis.set();
						label.innerText = 'Read more';
						bioTog.classList.remove('dropped');
					} else {
						ellipsis.unset();
						label.innerText = 'Read less';
						bioTog.classList.add('dropped');
					}
				});
			}
		});

		// Scroll Handling
		const controller = new ScrollMagic.Controller({
			globalSceneOptions: {},
		});

		var sections = $('.section-row');

		if ($('body').hasClass('section-counter')) {
			for (var i = 0; i < sections.length; i++) {
				new ScrollMagic.Scene({
					triggerElement: '#section0' + i,
					duration: $(sections[i]).outerHeight(true),
				})
					.setClassToggle('#pageSection0' + i, 'active')
					// .addIndicators()
					.addTo(controller);
			}
		}

		// Anchor Scroll
		controller.scrollTo(function (newpos) {
			TweenMax.to(window, 0.5, { scrollTo: { y: newpos } });
		});

		$(document).on(
			'click',
			'a[href^="#"]:not(".member-bio-toggle a")',
			function (e) {
				console.log('clicked');
				var id = $(this).attr('href');
				if ($(id).length > 0) {
					e.preventDefault();

					controller.scrollTo(id);

					if (window.history && window.history.pushState) {
						history.pushState('', document.title, id);
					}
				}
			}
		);
		// On Load Location Loading
		setTimeout(function () {
			if (location.hash) {
				window.scrollTo(0, 0);
				var scrollLocation = window.location.href.split('#')[1];
				$('html,body').animate(
					{ scrollTop: $('#' + scrollLocation).offset().top },
					1000
				);

				let element = document.querySelector(
					'[data-element=' + scrollLocation + ']'
				);

				if (!element.classList.contains('visible')) {
					element.classList.add('visible');
				}
			}
		}, 1);

		// Mobile Functions
		if (winWidth <= 576) {
			// Partner Slider
			$('.info-grid-container').slick({
				fade: true,
				prevArrow:
					'<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="far fa-arrow-left"></i></button>',
				nextArrow:
					'<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="far fa-arrow-right"></i></button>',
			});

			$('.blue-numbers').slick({
				fade: true,
				prevArrow:
					'<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="far fa-arrow-left"></i></button>',
				nextArrow:
					'<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="far fa-arrow-right"></i></button>',
			});
		}
	}
})(jQuery, window, document);
